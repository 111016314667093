import Vue from 'vue';
import Vuex from 'vuex';

import auth from './modules/auth.module';
import htmlClass from './modules/htmlclass.module';
import config from './modules/config.module';
import breadcrumbs from './modules/breadcrumbs.module';
import menu from './modules/menu.module';
import orderInfo from './modules/order/order-info.module';
import orderCustomerInfo from './modules/order/order-customer-info.module';
import orderDiscount from './modules/order/order-discount.module';
import orderNote from './modules/order/order-note.module';
import orderProducts from './modules/order/order-product-table.module';
import orderSeletedStatus from './modules/order/order-seleted-status.module';
import paymentIntallment from './modules/payment/payment-installment.module';
import warrantyBillInfo from './modules/warranty-bill/warranty-bill-info.module';
import exchangeProduct from './modules/warranty-bill/exchange-product.module';
import shipment from './modules/shipment/shipment-info.module';
import stores from './modules/store/store-info.module';
import requiredStocks from './modules/required-stock/required-stock.module';
import valuationRequests from './modules/trade-in/valuation-request.module';

import selectedBillIWarranty from './modules/warranty-bill/selected-bill-warranty.module';
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs,
    menu,
    orderInfo,
    orderCustomerInfo,
    orderDiscount,
    orderNote,
    orderProducts,
    orderSeletedStatus,
    paymentIntallment,
    warrantyBillInfo,
    exchangeProduct,
    shipment,
    stores,
    selectedBillIWarranty,
    requiredStocks,
    valuationRequests,
  },
});
