export const SET_VALUATION_REQUEST_SELECTED = 'setStatus';
export const PURGE_VALUATION_REQUEST_STATUS_SELECTED = 'removeStatus';

const formSelectedBase = [];

export default {
  state: {
    formSelected: [...formSelectedBase],
  },
  getters: {
    getValuationRequestStatus(state) {
      return state.formSelected;
    },
  },
  actions: {
    async [SET_VALUATION_REQUEST_SELECTED](state, payload) {
      state.commit(SET_VALUATION_REQUEST_SELECTED, payload);
    },
    [PURGE_VALUATION_REQUEST_STATUS_SELECTED](state) {
      state.commit(PURGE_VALUATION_REQUEST_STATUS_SELECTED);
    },
  },
  mutations: {
    [SET_VALUATION_REQUEST_SELECTED](state, payload) {
      state.formSelected = payload;
    },
    [PURGE_VALUATION_REQUEST_STATUS_SELECTED](state) {
      state.formSelected = [...formSelectedBase];
    },
  },
};
