const { values } = require('lodash');
const { ORDER_STATUS, VALUATION_REQUEST_STATUS } = require('./enum');
const { VALUATION_REQUEST_STATUS_NAME } = require('./enum-name');

const ApiService = require('../core/services/api.service').default;

module.exports = {
  GET_CUSTOMER: 'http://zotief8.com/api/customers',
  CREATE_CUSTOMER: 'http://zotief8.com/api/customers',
  GET_CITY: 'http://zotief8.com/api/cities',
  MESSAGE_ERROR: 'Lỗi hệ thống !',
  IMPORT_STOCK: 1,
  EXPORT_STOCK: 2,
  TRANSFER_STOCK: 3,
  PRODUCT_IMEI: 2,
  //  Order
  ORDER_NEW: 1, // Mới
  ORDER_CONFIRMING: 2, // Đang xác nhận
  ORDER_CONFIRMED: 3, // Đã xác nhận
  ORDER_SUCCESS: 4, // Thành công
  ORDER_PACKING: 5, // Đang đóng gói
  ORDER_CUSTOMER_CANCEL: 6, // Khách hủy
  ORDER_SYSTEM_CANCEL: 7, // Hệ thống hủy
  ORDER_OUT_OF_STOCK: 8, // Hết hàng
  TRANSACTION_TYPE: [
    {
      value: '1',
      name: 'CashIn',
      text: 'Phiếu thu',
      group: 'cash',
      mode: 'in',
      parent: 'cashbook',
    },
    {
      value: '2',
      name: 'CashOut',
      text: 'Phiếu chi',
      group: 'cash',
      mode: 'out',
      parent: 'cashbook',
    },
    {
      value: '3',
      name: 'BankIn',
      text: 'Báo có (nộp tiền)',
      group: 'bank',
      mode: 'in',
      parent: 'cashbook',
    },
    {
      value: '4',
      name: 'BankOut',
      text: 'Báo nợ (rút tiền)',
      group: 'bank',
      mode: 'out',
      parent: 'cashbook',
    },
    {
      value: '8',
      name: 'StockImport',
      text: 'Phiếu nhập',
      group: 'goods',
      mode: 'all',
    },
    {
      value: '9',
      name: 'GoodsIssue',
      text: 'Phiếu xuất',
      group: 'goods',
      mode: 'all',
    },
    {
      value: '6',
      name: 'Sale',
      text: 'Phiếu bán hàng',
      group: 'sale',
      mode: 'all',
    },
  ],

  CONTACT_TYPE: [
    {
      value: '1',
      name: 'Customer',
      text: 'Khách hàng',
    },
    {
      value: '2',
      name: 'Provider',
      text: 'Nhà cung cấp',
    },
    {
      value: '3',
      name: 'Installment',
      text: 'Dịch vụ trả góp',
    },
    {
      value: '4',
      name: 'Employee',
      text: 'Nhân viên',
    },
    {
      value: '5',
      name: 'Credit',
      text: 'Quẹt thẻ',
    },
    {
      value: '6',
      name: 'ShiftWork',
      text: 'Kết ca làm việc',
    },
    {
      value: '21',
      name: 'Ecommerce',
      text: 'Sàn thương mại điện tử',
    },
    {
      value: '22',
      name: 'ShipFee',
      text: 'Phí ship',
    },
    {
      value: '20',
      name: 'Other',
      text: 'Khác',
    },
  ],

  DOC_TYPE: [
    {
      value: '1',
      name: 'Bill',
      text: 'Phiếu XNK',
    },
    {
      value: '2',
      name: 'Order',
      text: 'Đơn hàng',
    },
  ],

  TIME_TRIGGER: 500,

  URL_IMPORT_PRODUCT_TRADE_IN_PROGRAM:
    'https://drive.google.com/uc?id=1y-4uyfxdjvVOkWLvMOJt7gOYDpxhEZd0',
  URL_IMPORT_PRODUCT_ACCESSORY:
    'https://drive.google.com/uc?id=1vkgxBgeUPLxgt-1cPHm_QrRDpk1jZDpI',
  URL_IMPORT_PRODUCT_PROMOTION:
    ApiService.getServerInformation().read +
    'media/upload/promotion/Temp_Import_Promotion.xlsx',
  URL_IMPORT_PRODUCT_FOR_ACCESSORY:
    'https://drive.google.com/uc?id=1zUr3i-xsxXL_cr6zZJ9tFj6WjrZV1y0i',
  URL_IMPORT_PRODUCT_FOR_PROMOTION:
    'https://drive.google.com/uc?id=1TkEOYNTY-okpxR-HK-uw75wLcXobrSXa',
  URL_IMPORT_PRODUCT_WARRANTY:
    'https://drive.google.com/uc?id=1hnASqIB1fey3WxHxYOtrzeU5DmgnyMKJ',
  URL_IMPORT_PRODUCT_FOR_WARRANTY:
    'https://drive.google.com/uc?id=1wzY7vUGXaqWMJZ-1t_WGltxSnwn4v3wt',
  URL_IMPORT_PRODUCT_DISCOUNT:
    'https://drive.google.com/uc?id=1UkJhxajsX_8TokYjGRJHKK37z6DKL21X',
  URL_IMPORT_REWARD_PRODUCT_CATEGORY:
    'https://drive.google.com/uc?id=1Ghp-fbIqWBPjNfMke-xTVklg203T_-cy',
  URL_IMPORT_REWARD_PROGRAM:
    'https://drive.google.com/uc?id=1x1HsYKWcBTYqFbFWPIKwzwhGgGYnO21P',
  URL_IMPORT_EMPLOYEE_REWARD:
    'https://drive.google.com/uc?id=14N1MEH8ofZy5yqGJ1Q4bSVFpCDtO1Pt5',
  URL_IMPORT_EMPLOYEE:
    'https://drive.google.com/uc?id=12wZqnEa_2JjAzDUba-NolcIXJTqxNQ8i',
  BASE_URL: ApiService.getServerInformation().read, // tương đương với api read
  URL_IMPORT_REWARD_ACCESSORY_PROGRAM:
    'https://drive.google.com/uc?id=1phCAdI59KxalrS-HgCVm6HyU1vQs9irz',
  URL_IMPORT_PRODUCT_COMPONENT:
    'https://drive.google.com/uc?id=1IPu5xpdgD_yiZOkpQ8DEnA319SSSz6l4',
  URL_IMPORT_PRODUCT_COUPON:
    'https://drive.google.com/uc?id=1N7MzX8C-QLmQdgx-gkJhHCvFBjpxwLpS',
  URL_IMPORT_AHAMOVE:
    'https://drive.google.com/uc?id=13UEmzUkB1rjH1K-vSbblsAqk9s-iCxk3',
  URL_IMPORT_PRODUCT:
    ApiService.getServerInformation().read +
    'media/upload/product/DDV_ImportProduct.xlsx',
  URL_IMPORT_PRODUCT_COMBO:
    'https://drive.google.com/uc?id=17VQ748Ni4tEAQW22WXIScN9v7phzh3wW',
  URL_IMPORT_REWARD_DETAIL:
    'https://drive.google.com/uc?id=14EJg1Dk6kPSvGFP3QB_YuUaMxuOjY0jo',
  URL_IMPORT_COUPON:
    'https://drive.google.com/uc?id=1Cg93Hn4LrqOmWjtjyxB6HIWWERRIAvGt',
  URL_IMPORT_STOCKSLIP:
    'https://drive.google.com/uc?id=14KC-yk4kBFXqj2RKvA4xgF7UqjBMXhFC',
  URL_IMPORT_PRODUCT_VIRTUAL_STOCK:
    'https://drive.google.com/uc?id=1a55ysM9z3DVm3-rx8HOtV7QN_vHiIRPn',
  URL_IMPORT_PRODUCT_PO:
    'https://drive.google.com/uc?id=17G8K-wbsEb6BbaQ2ohY1FepdsWCH_VE9',
  URL_IMPORT_PRODUCT_PRINT:
    'https://drive.google.com/uc?id=1kID1tnEACoAlkWK6vnEpBt-g0NprMO65',
  URL_IMPORT_CHANGE_PRODUCT_IMEI:
    'https://spreadsheets.google.com/feeds/download/spreadsheets/Export?key=17MfIt-JNLmeKxJU6dlrKm0BNqnt9ejHZlnWLkn2y0_k&exportFormat=xlsx',
  URL_IMPORT_ACTIVE_IMEI:
    'https://drive.google.com/uc?id=12MlIPZeGa4Eg3-E_pHeELvjORpezgji3',
  URL_IMPORT_PRODUCT_PO_SHARE:
    'https://drive.google.com/uc?id=1QIWrHLOHqF9xEEtTKCc7bMJbOD0oUAXb',
  URL_OFFER_SUGGESTION_FORM:
    'https://docs.google.com/forms/d/e/1FAIpQLSetWcf1SRsZjz2fnvL7BlLvavccBI9b_nNh1LspDrd4XjTxBA/viewform',
  URL_PROMOTION_PROGRAM_MARKETING:
    'https://drive.google.com/drive/folders/1Uy-0IDztoHR2-Bx6TQ7SK2agOCCpfuWa',
  URL_PRODUCTS_IMPORT_TRADE_INS:
    'https://drive.google.com/uc?id=1ho70PDPnxLoRbbi-in5QMp7KLBu-gZih',
  URL_PRODUCTS_UPDATE_TRADE_INS:
    'https://docs.google.com/spreadsheets/d/1fq0nJrM51_Bz3vieFq44XK38TUF6yT8F/export?format=xlsx',
  URL_PRODUCTS_IMPORT_INVENTORY_CHECK:
    'https://docs.google.com/spreadsheets/d/10Az01_D2B7ait5I2j4u2REWYrVtqCaUI/export?format=xlsx',
  CONTACT_TYPE_CATE: [
    {
      id: 1,
      name: 'BGĐ',
    },
    {
      id: 2,
      name: 'Nhân viên cửa hàng',
    },
    {
      id: 3,
      name: 'Phòng  Bp. MKT',
    },
    {
      id: 4,
      name: 'Phòng  Bp. Kế toán',
    },
    {
      id: 5,
      name: 'Phòng  Bp. HR',
    },
    {
      id: 6,
      name: 'Phòng  Bp. NH',
    },
    {
      id: 7,
      name: 'Phòng  Bp. CR',
    },
    {
      id: 8,
      name: 'Phòng Bp. Techmag',
    },
    {
      id: 9,
      name: 'Phòng Bp. Kho & ĐP',
    },
    {
      id: 10,
      name: 'Giao nhận',
    },
    {
      id: 11,
      name: 'Nhà cung cấp',
    },
    {
      id: 12,
      name: 'Khách hàng',
    },
    {
      id: 20,
      name: 'Đối tượng khác',
    },
  ],
  LIMIT_BILL: 200,
  LIMIT_LIST_PRODUCT: 10,
  DEFAULT_TITLE: 'Di Động Việt',
  VERSION_APP: '24102021',
  ORDER_STATUS: [
    { value: null, text: 'Chọn trạng thái' },
    { value: '1', text: 'Mới', checked: false, group: 1 },
    { value: '2', text: 'Đang xử lí', checked: false, group: 1 },
    { value: '10', text: 'Không bắt máy', checked: false, group: 1 },
    { value: '15', text: 'Đang theo dõi', checked: false, group: 1 },
    { value: '17', text: 'Giữ cọc', checked: false, group: 1 }, //IP14
    { value: '3', text: 'Đã xác nhận', checked: false, group: 1 },
    { value: '5', text: 'Đang đóng gói', checked: false, group: 1 },
    // { value: '13', text: 'Đồng ý cọc', checked: false, group: 1 },
    // { value: '12', text: 'Đã đặt cọc', checked: false, group: 1 },
    { value: '14', text: 'Đã chuẩn bị', checked: false, group: 2 },
    { value: '4', text: 'Đang giao', checked: false, group: 3 },
    { value: '6', text: 'Thành công', checked: false, group: 4 },
    { value: '9', text: 'Đã chuyển hoàn', checked: false, group: 5 },
    { value: '7', text: 'Không thành công', checked: false, group: 6 },
    { value: '8', text: 'Hệ thống hủy', checked: false, group: 6 },
    { value: '11', text: 'CR huỷ', checked: false, group: 6 },
  ],
  ORDER_STATUS_CR: [
    { value: null, text: 'Chọn trạng thái' },
    { value: '1', text: 'Mới', checked: false, group: 1, position: 0 },
    { value: '2', text: 'Đang xử lí', checked: false, group: 1, position: 1 },
    {
      value: '10',
      text: 'Không bắt máy',
      checked: false,
      group: 1,
      position: 2,
    },
    { value: '3', text: 'Đã xác nhận', checked: false, group: 1, position: 3 },
    {
      value: '15',
      text: 'Đang theo dõi',
      checked: false,
      group: 1,
      position: 4,
    },
    { value: '11', text: 'CR huỷ', checked: false, group: 6, position: 6 },
  ],
  ORDER_STATUS_SHOP: [
    { value: null, text: 'Chọn trạng thái' },
    { value: '3', text: 'Đã xác nhận', checked: false, group: 1, position: 1 },
    { value: '5', text: 'Đang đóng gói', checked: false, group: 1 },
    { value: '14', text: 'Đã chuẩn bị', checked: false, group: 2, position: 3 },
    { value: '4', text: 'Đang giao', checked: false, group: 3 },
    {
      value: '7',
      text: 'Không thành công',
      checked: false,
      group: 6,
      position: 4,
    },
  ],
  ORDER_STATUS_DP: [
    { value: null, text: 'Chọn trạng thái' },
    {
      value: '5',
      text: 'Đang đóng gói',
      checked: false,
      group: 1,
      position: 1,
    },
    { value: '4', text: 'Đang giao', checked: false, group: 3, position: 2 },
    { value: '6', text: 'Thành công', checked: false, group: 4, position: 3 },
    {
      value: '9',
      text: 'Đã chuyển hoàn',
      checked: false,
      group: 5,
      position: 4,
    },
    {
      value: '7',
      text: 'Không thành công',
      checked: false,
      group: 6,
      position: 5,
    },
  ],
  ORDER_CANCEL_STATUS: [
    { value: null, text: 'Chọn lý do' },
    { value: '1', text: 'Đã mua tại quầy' },
    { value: '2', text: 'Đơn trùng' },
    { value: '3', text: 'Đã mua nơi khác' },
    { value: '4', text: 'Không liên hệ được khách hàng' },
    { value: '5', text: 'Khách suy nghĩ thêm' },
    { value: '6', text: 'Hết hàng' },
    { value: '8', text: 'Khách không đến' },
    { value: '9', text: 'Trả góp không thành công' },
    { value: '10', text: 'Thu cũ không thành công' },
    { value: '11', text: 'Khách không nghe máy' },
    { value: '12', text: 'Khách không đợi tại cửa hàng' },
    { value: '13', text: 'Tham khảo suy nghĩ thêm' },
    { value: '14', text: 'Giá máy thu cũ thấp' },
    { value: '15', text: 'Khách hết nhu cầu' },
    { value: '16', text: 'Không đồng ý hình thức sản phẩm' },
    { value: '17', text: 'Đơn hàng spam' },
    { value: '18', text: 'So sánh giá' },
    { value: '19', text: 'Quá 48 tiếng' },
    { value: '7', text: 'Lý do khác' },
  ],
  ORDER_CANCEL_STATUS_CR: [
    { value: '1', text: 'Đã mua tại quầy' },
    { value: '2', text: 'Đơn trùng' },
    { value: '3', text: 'Đã mua nơi khác' },
    { value: '5', text: 'Khách suy nghĩ thêm' },
    { value: '6', text: 'Hết hàng' },
    { value: '9', text: 'Trả góp không thành công' },
    { value: '4', text: 'Không liên hệ được khách hàng' },
    { value: '13', text: 'Tham khảo suy nghĩ thêm' },
    { value: '14', text: 'Giá máy thu cũ thấp' },
    { value: '17', text: 'Đơn hàng spam' },
    { value: '8', text: 'Khách không đến' },
  ],
  ORDER_CANCEL_STATUS_SHOP: [
    { value: '8', text: 'Khách không đến' },
    { value: '15', text: 'Khách hết nhu cầu' },
    { value: '4', text: 'Không liên hệ được khách hàng' },
    { value: '3', text: 'Đã mua nơi khác' },
    { value: '6', text: 'Hết hàng' },
    { value: '13', text: 'Tham khảo suy nghĩ thêm' },
    { value: '9', text: 'Trả góp không thành công' },
    { value: '10', text: 'Thu cũ không thành công' },
    { value: '16', text: 'Không đồng ý hình thức sản phẩm' },
    { value: '18', text: 'So sánh giá' },
    { value: '19', text: 'Quá 48 tiếng' },
    { value: '2', text: 'Đơn trùng' },
  ],
  ORDER_CANCEL_STATUS_DP: [
    { value: '1', text: 'Đã mua tại quầy' },
    { value: '2', text: 'Đơn trùng' },
    { value: '3', text: 'Đã mua nơi khác' },
    { value: '4', text: 'Không liên hệ được khách hàng' },
    { value: '5', text: 'Khách suy nghĩ thêm' },
    { value: '6', text: 'Hết hàng' },
    { value: '8', text: 'Khách không đến' },
    { value: '9', text: 'Trả góp không thành công' },
    { value: '10', text: 'Thu cũ không thành công' },
    { value: '11', text: 'Khách không nghe máy' },
    { value: '12', text: 'Khách không đợi tại cửa hàng' },
    { value: '13', text: 'Tham khảo suy nghĩ thêm' },
    { value: '14', text: 'Giá máy thu cũ thấp' },
    { value: '15', text: 'Khách hết nhu cầu' },
    { value: '16', text: 'Không đồng ý hình thức sản phẩm' },
    { value: '17', text: 'Đơn hàng spam' },
    { value: '18', text: 'So sánh giá' },
    { value: '19', text: 'Quá 48 tiếng' },
    { value: '7', text: 'Lý do khác' },
  ],
  BILL_TYPE: [
    { value: null, text: 'Loại hoá đơn' },
    { value: '1', text: 'Bán lẻ' },
    { value: '2', text: 'Bán buôn' },
    { value: '3', text: 'Trả hàng' },
    { value: '4', text: 'Giao hàng' },
    { value: '6', text: 'Bảo hành' },
  ],
  PRODUCT_CHECK_STATUS: [
    { value: null, text: 'Tình trạng' },
    { value: 1, text: 'Mất' },
    { value: 2, text: 'Hư hỏng' },
    { value: 3, text: 'Bán nhầm mã' },
    { value: 5, text: 'Tình trạng khác' },
    { value: 4, text: 'Đủ' },
  ],
  CUSTOMER_GENDER: [
    { id: null, name: 'Chọn giới tính' },
    { id: 0, name: 'Nữ' },
    { id: 1, name: 'Nam' },
  ],
  PREORDER_STATUS: [
    { id: null, name: 'Chọn trạng thái' },
    { id: 1, name: 'Mới', checked: false },
    // { id: 2, name: 'Đã tư vấn', checked: false },
    { id: 3, name: 'Dữ liệu sai', checked: false },
    { id: 10, name: 'Dữ liệu trùng', checked: false },
    { id: 4, name: 'Tìm hiểu thêm', checked: false },
    { id: 5, name: 'Đồng ý cọc', checked: false },
    { id: 9, name: 'Đã cọc', checked: false },
    { id: 6, name: 'Chưa nghe máy', checked: false },
    { id: 7, name: 'Đã hết nhu cầu', checked: false },
    { id: 8, name: 'Máy không thu', checked: false },
    { id: 11, name: 'Hết hàng', checked: false },
    { id: 12, name: 'Không có nhu cầu', checked: false },
  ],
  PAYMENT_STATUS: [
    { id: null, name: 'Chọn thanh toán' },
    { id: 1, name: 'Thành công' },
    { id: 2, name: 'Thất bại' },
    { id: 3, name: 'Đồng ý cọc' },
    { id: 4, name: 'Đã đặt cọc' },
  ],
  CONSTANT_WANT_UPGRADE: [
    { id: null, name: 'Chọn khách muốn lên đời' },
    { id: 0, name: 'Không' },
    { id: 1, name: 'Có' },
  ],
  ORDER_TYPE: [
    { id: null, name: 'Chọn loại' },
    { id: 1, name: 'Mua tại quầy' },
    { id: 2, name: 'Đặt trước' },
    { id: 3, name: 'Tự vận chuyển' },
    { id: 4, name: 'Hãng vận chuyển (Ahamove, Nhất tín ...)' },
    { id: 5, name: 'Website' },
  ],
  CRITERIA: [
    {
      id: 1,
      name: 'Có tiền chiết khấu',
      checked: false,
    },
    {
      id: 2,
      name: 'Không có chiết khấu',
      checked: false,
    },
    {
      id: 13,
      name: 'Có đặt cọc',
      checked: false,
    },
    {
      id: 3,
      name: 'Có tiền chuyển khoản',
      checked: false,
    },
    {
      id: 4,
      name: 'Có thanh toán quẹt thẻ',
      checked: false,
    },
    {
      id: 5,
      name: 'Có thanh toán trả góp',
      checked: false,
    },
    {
      id: 6,
      name: 'Có dùng tích điểm',
      checked: false,
    },
    {
      id: 10,
      name: 'Có tiền mặt',
      checked: false,
    },
    {
      id: 7,
      name: 'Có công nợ',
      checked: false,
    },
    {
      id: 8,
      name: 'Còn công nợ',
      checked: false,
    },
    {
      id: 9,
      name: 'Hết công nợ',
      checked: false,
    },
    {
      id: 16,
      name: 'Chưa có mã vận đơn',
      checked: false,
    },
    {
      id: 17,
      name: 'Có mã coupon',
      checked: false,
    },
  ],
  ONE_HUNDRED: 100,
  TIME_OUT: 600000,
  LIMIT: 100000,
  PAGE: 1,
  DEFAULT_COMANY_ID: 3,
  JOB_TITLE: [
    { id: null, name: 'Hãy chọn chức vụ' },
    { id: 1, name: 'Giám đốc' },
    { id: 2, name: 'Quản lý vùng' },
    { id: 3, name: 'Quản lý khu vực' },
    { id: 4, name: 'Quản lý cửa hàng' },
    { id: 5, name: 'Trợ lý cửa hàng' },
    { id: 6, name: 'Kế toán bán hàng' },
    { id: 7, name: 'Tư vấn bán hàng' },
    { id: 8, name: 'Hỗ trợ kỹ thuật' },
    { id: 9, name: 'Tiếp đón khách hàng' },
    { id: 10, name: 'Kỹ thuật phần cứng' },
    { id: 11, name: 'Giám đốc NHPK' },
    { id: 12, name: 'Nhân viên phụ trách NHPK' },
    { id: 13, name: 'Admin NHPK' },
    { id: 14, name: 'Chuyên doanh phụ kiện' },
    { id: 15, name: 'Trưởng CSKH' },
    { id: 16, name: 'Nhân viên CSKH' },
    { id: 17, name: 'Cộng tác viên CSKH' },
    { id: 18, name: 'Giám đốc NHPK' },
    { id: 19, name: 'Kế toán nội bộ' },
    { id: 20, name: 'Trưởng bộ phận điều phối' },
    { id: 21, name: 'Nhân viên marketing' },
    { id: 22, name: 'Trưởng marketing' },
    { id: 23, name: 'Điều phối' },
    { id: 24, name: 'Trưởng kế toán nội bộ' },
    { id: 88, name: 'Team leader CR LQĐ' },
    { id: 89, name: 'Team leader CR TQK' },
  ],
  REWARD_PROGRAM_TYPE: {
    HH_CH: 1,
    HH_NHPK: 2,
    HH_CR: 3,
  },
  INS_MONTH_NO: [
    { id: 3, name: '3 Tháng' },
    { id: 6, name: '6 Tháng' },
    { id: 9, name: '9 Tháng' },
    { id: 12, name: '12 Tháng' },
    { id: 24, name: '24 Tháng' },
  ],
  INS_PREPAY_PERCENT: [
    { id: 3, name: '10 %' },
    { id: 6, name: '20 %' },
    { id: 9, name: '30 %' },
    { id: 12, name: '40 %' },
    { id: 24, name: '50 %' },
  ],
  LOAN_STATUS: [
    { value: 1, text: 'Mới', checked: false },
    { value: 2, text: 'Đã gửi hồ sơ', checked: false },
    { value: 3, text: 'Đã duyệt', checked: false },
    { value: 4, text: 'Đã hủy', checked: false },
    { value: 5, text: 'Đã giải ngân', checked: false },
    { value: 6, text: 'Đã ký hợp đồng vay', checked: false },
  ],
  LOAN_TENOR: [
    { id: '202011062', name: '6 Tháng', value: 6 },
    { id: '202011061', name: '9 Tháng', value: 9 },
    { id: '202011064', name: '12 Tháng', value: 12 },
    { id: '202011067', name: '15 Tháng', value: 15 },
    { id: '202011065', name: '18 Tháng', value: 18 },
    { id: '202011068', name: '21 Tháng', value: 21 },
    { id: '202011066', name: '24 Tháng', value: 24 },
    { id: '2021060301', name: '27 Tháng', value: 27 },
    { id: '202011069', name: '30 Tháng', value: 30 },
    { id: '2021060302', name: '33 Tháng', value: 33 },
    { id: '2020110610', name: '36 Tháng', value: 36 },
    { id: '2021060303', name: '39 Tháng', value: 39 },
    { id: '2020110611', name: '42 Tháng', value: 42 },
    { id: '2021060304', name: '45 Tháng', value: 45 },
    { id: '2020110612', name: '48 Tháng', value: 48 },
  ],
  SHINHAN_ID: 100000011,
  CUSTOMER_TYPE: [
    {
      id: 1,
      name: 'Khách lẻ',
    },
    {
      id: 2,
      name: 'Khách buôn',
    },
    {
      id: 3,
      name: 'Đại lý',
    },
    {
      id: 4,
      name: 'Khách VIP',
    },
    {
      id: 5,
      name: 'KOL',
    },
  ],
  APPLY_COUPON: [
    {
      id: 1,
      name: 'Khách lẻ',
    },
    {
      id: 6,
      name: 'Segment',
    },
  ],
  CUSTOMER_APPELLATION: [
    { id: null, name: 'Chọn danh xưng' },
    {
      id: 1,
      name: 'Anh',
    },
    {
      id: 2,
      name: 'Chị',
    },
  ],
  PURCHASE_ORDER_TYPE: [
    {
      id: 1,
      name: 'Đặt cọc',
    },
    {
      id: 2,
      name: 'Thanh toán tiền ngay',
    },
    {
      id: 3,
      name: 'Bù trừ đơn hàng',
    },
    {
      id: 4,
      name: 'Công nợ',
    },
    {
      id: 5,
      name: 'Ký gửi',
    },
  ],
  DEPARTMENT: [
    {
      id: 1,
      name: 'CR Trần Quang Khải',
    },
    {
      id: 2,
      name: 'CR Lê Quang Định',
    },
  ],
  BILL_NHANH_TYPE: {
    1: 'Loại nhập kho',
    2: 'Loại xuất kho',
  },
  BILL_NHANH_MODE: {
    1: 'Kiểu giao hàng',
    2: 'Kiểu bán lẻ',
    3: 'Kiểu chuyển kho',
    4: 'Kiểu quà tặng',
    5: 'Kiểu nhà cung cấp',
    6: 'Kiểu bán buôn',
    8: 'Kiểu kiểm kho',
    10: 'Kiểu khác',
  },
  WARRANTY_REQUEST_STATUS: [
    { id: null, name: 'Chọn trạng thái' },
    { id: 1, name: 'Đã đặt lịch BH' },
    { id: 2, name: 'Thất bại' },
  ],
  STEP_ROLE_TYPE: [
    {
      roleType: 1,
      roleIcon: 'fas fa-headset text-dark',
      roleText: 'Chăm sóc KH',
      roleTypeText: 'Đơn CR',
    },
    {
      roleType: 2,
      roleIcon: 'fas fa-store text-warning',
      roleText: 'Khối cửa hàng',
      roleTypeText: 'Cửa hàng',
    },
    {
      roleType: 3,
      roleIcon: 'fas fa-truck text-danger',
      roleText: 'Điều phối',
      roleTypeText: 'Điều phối',
    },
  ],
  CONSTANT_PARTNER_PAYMENT: [
    {
      id: 1,
      name: 'Thành công',
    },
    {
      id: 2,
      name: 'Thất bại',
    },
    {
      id: 3,
      name: 'Đồng ý cọc',
    },
    {
      id: 4,
      name: 'Đã đặt cọc',
    },
  ],
  SAMSUNG_STATUS: {
    READY: 1,
    ON_ACTIVE: 2,
    ACTIVED: 3,
    ERROR: 4,
  },
  VAT_TYPE: [
    { text: 'Công ty', value: 1 },
    { text: 'Cá nhân', value: 2 },
  ],
  VAT_SYNC_STATUS: [
    { id: 1, name: 'Mới' },
    { id: 2, name: 'Gửi thất bại' },
    { id: 3, name: 'Gửi thành công' },
    { id: 4, name: 'Đã phát hành' },
  ],
  APPROVAL_TYPE: [
    { id: 1, name: 'Chi hoàn tiền thanh toán dư', group: 'sale-bill' },
    { id: 2, name: 'Chi thu cũ', group: 'trade-in' },
    { id: 3, name: 'Chi thanh toán trả hàng', group: 'return-bill' },
    { id: 4, name: 'Chi thu cũ - đổi mới', group: 'trade-in' },
    { id: 5, name: 'Chi xuất hóa đơn VAT', group: 'sale-bill' },
    { id: 6, name: 'Hoàn cọc', group: 'order' },
  ],
  APPROVAL_STATUS: [
    { id: null, name: 'Chọn trạng thái' },
    { id: 1, name: 'Mới', checked: false },
    { id: 2, name: 'Đã duyệt', checked: false },
    { id: 3, name: 'Đã hủy', checked: false },
    { id: 4, name: 'Đã thanh toán', checked: false },
    { id: 5, name: 'Thanh toán KTC', checked: false },
    { id: 6, name: 'Hoàn thành', checked: false },
    { id: 7, name: 'Đang duyệt', checked: false },
    { id: 8, name: 'Đã hậu kiểm', checked: false },
    { id: 9, name: 'Đã lập lệnh', checked: false },
  ],
  APPROVAL_STATUS_ACCOUNTANT_ONLY: [
    { id: 5, name: 'Thanh toán KTC', checked: false },
    { id: 9, name: 'Đã lập lệnh', checked: false },
  ],
  FAIL_REASON: [
    { id: null, name: 'Chọn trạng thái' },
    { id: 1, name: 'Sai thông tin chuyển khoản', checked: false },
  ],
  APPROVAL_PAYMENT_TYPE: [
    { id: null, name: 'Hình thức hoàn tiền' },
    { id: 1, name: 'Tiền mặt' },
    { id: 2, name: 'Chuyển khoản' },
  ],
  TRANSFER_STORE_STATUS: [
    { value: null, text: 'Tất cả' },
    {
      value: 1,
      text: 'Chờ duyệt',
      variant: 'label-light-info',
      textVariant: 'text-info',
    },
    {
      value: 2,
      text: 'Đã duyệt',
      variant: 'label-light-success',
      textVariant: 'text-success',
    },
    {
      value: 3,
      text: 'Bị từ chối',
      variant: 'label-light-danger',
      textVariant: 'text-danger',
    },
    {
      value: 4,
      text: 'Duyệt và chuyển',
      variant: 'label-light-primary',
      textVariant: 'text-primary',
    },
  ],
  PRODUCT_TYPES: [
    {
      id: 1,
      name: 'Sản phẩm IMEI',
    },
    {
      id: 2,
      name: 'Sản phẩm',
    },
  ],
  PRODUCT_RELATIONSHIP_TYPES: [
    {
      id: 0,
      name: 'Tất cả sản phẩm',
    },
    {
      id: 1,
      name: 'Sản phẩm cha',
    },
    {
      id: 2,
      name: 'Sản phẩm con',
    },
    {
      id: 3,
      name: 'Sản phẩm độc lập',
    },
    {
      id: 4,
      name: 'Sản phẩm cha + độc lập',
    },
    {
      id: 5,
      name: 'Sản phẩm con + độc lập',
    },
  ],
  APPROVAL_ENTITY_TYPES: [
    {
      id: 1,
      name: 'Hóa đơn',
    },
    {
      id: 2,
      name: 'Đơn hàng',
    },
    {
      id: 3,
      name: 'Phiếu thu cũ',
    },
    {
      id: 4,
      name: 'Phiếu trả hàng',
    },
  ],
  ALLOWANCE_CRITERIA: [
    {
      key: 'targetRevenue',
      name: 'Doanh số theo cửa hàng',
    },
    {
      key: 'targetInventoryTime',
      name: 'Vòng quay hàng tồn kho',
    },
    {
      key: 'happyCustomer',
      name: 'Khách hàng hài lòng',
    },
    {
      key: 'targetLayOff',
      name: 'Sa thải',
    },
    {
      key: 'targetRevenueMBF',
      name: 'Doanh số theo MBF',
    },
    {
      key: 'targetRepair',
      name: 'Doanh số sửa chữa',
    },
    {
      key: 'targetWarranty',
      name: 'Doanh số GBH',
    },
    {
      key: 'targetAccessory',
      name: 'Doanh số phụ kiện',
    },
    {
      key: 'targetAccessoryInventoryTime',
      name: 'Vòng quay tồn kho phụ kiện',
    },
    {
      key: 'targetComponentInventoryTime',
      name: 'Vòng quay tồn kho linh kiện',
    },
    {
      key: 'serviceSuccessRate',
      name: 'Tỉ lệ phục vụ thành công',
    },
    {
      key: 'activeActivities',
      name: 'Hoạt động hoạt náo',
    },
  ],
  REWARD_DETAIL_REASON: [
    {
      id: null,
      name: 'Chọn lý do',
    },
    {
      id: 1,
      name: 'Lỗi cá nhân - tác phong đẹp',
    },
    {
      id: 2,
      name: 'Lỗi cá nhân - khác',
    },
    {
      id: 3,
      name: 'Lỗi Cửa hàng - Hình ảnh cửa hàng',
    },
    {
      id: 4,
      name: 'Lỗi Cửa hàng Khác',
    },
  ],
  DATETIME_FORMAT: 'DD/MM/YYYY HH:mm',
  DATE_FORMAT: 'DD/MM/YYYY',
  DATE_DB_FORMAT: 'YYYY-MM-DD',
  SUCCESS_RESPONSE_STATUS: 1,
  APPLY_TO_ALL_BRANDS: -1,
  APPLY_TO_ALL_STORES: -1,
  MAX_ROWS_ALLOW_IMPORTING: 500,
  EMPTY_HEADER_REGEX: /__EMPTY(_\d+)?/g,
  WORKBOOK_READ_TYPE: 'binary',

  FORM_DATA_POSM_SUBMIT: {
    OUTER_KEY: 'store',
    INTERIOR_CATEGORIES: 'interior',
    EXTERIOR_CATEGORIES: 'exterior',
  },
  TIMEOUT_TO_DISABLE_BUTTON: 1000,
  TIMEOUT: {
    ORDER_STATUS_CHANGE: 300,
  },
  TIMEOUT_TO_SCROLL_TO: 300,
  ALLOWED_IMAGE_EXTENSIONS: [
    'jpg',
    'jpeg',
    'png',
    'gif',
    'tif',
    'tiff',
    'jfif',
    'bmp',
  ],
  TRADE_IN_TYPE: [
    {
      id: 1,
      name: 'Khách thu cũ đổi mới',
    },
    {
      id: 2,
      name: 'Khách chỉ bán lại máy',
    },
    {
      id: 3,
      name: 'Khách đổi bảo hành',
    },
  ],
  TRADE_IN_STATUS_OPTIONS: [
    {
      id: null,
      name: 'Chọn trạng thái',
    },
    {
      id: 1,
      name: 'Đã báo giá',
    },
    {
      id: 2,
      name: 'Đã thu cũ',
    },
  ],
  DEFAULT_PAGINATION: {
    SIZE: 20,
    PAGE: 1,
  },
  ACTIVE_STATUS_LIST: [
    {
      id: null,
      name: 'Chọn trạng thái',
    },
    {
      id: 1,
      name: 'Hoạt động',
    },
    {
      id: 0,
      name: 'Đã khóa',
    },
  ],
  LIST_CHANEL: [
    {
      id: 1,
      name: 'Offline & Online',
    },
    {
      id: 2,
      name: 'Offline',
    },
    {
      id: 3,
      name: 'Online',
    },
  ],
  DATE_LENGTH: {
    WEEK: 7,
    HALF_MONTH: 14,
    MONTH: 30,
    YEAR: 365,
  },
  VERTU_STORES: [
    {
      id: 101883,
      name: 'Vertu Caravelle',
    },
    {
      id: 101852,
      name: 'Vertu HN 3A Lê Thái Tổ',
    },
    {
      id: 101851,
      name: 'Vertu 79 Đồng Khởi',
    },
  ],
  SHOULD_PRINT_WARRANTY_BENEFIT_FOR_ORDER_STATUS: [ORDER_STATUS.DELIVERY],
  PRODUCT_TYPE_SEARCH: [
    {
      id: 0,
      name: 'Tất cả sản phẩm',
    },
    {
      id: 1,
      name: 'Sản phẩm cha',
    },
    {
      id: 2,
      name: 'Sản phẩm con',
    },
    {
      id: 3,
      name: 'Sản phẩm độc lập',
    },
    {
      id: 4,
      name: 'Sản phẩm cha + độc lập',
    },
    {
      id: 5,
      name: 'Sản phẩm con + độc lập',
    },
  ],
  VALUATION_REQUEST_STATUS: [
    { value: null, text: 'Chọn trạng thái' },
    {
      value: VALUATION_REQUEST_STATUS.NEW,
      text: VALUATION_REQUEST_STATUS_NAME[VALUATION_REQUEST_STATUS.NEW],
      checked: false,
      group: 1,
      position: 0,
    },
    {
      value: VALUATION_REQUEST_STATUS.PROCESSING,
      text: VALUATION_REQUEST_STATUS_NAME[VALUATION_REQUEST_STATUS.PROCESSING],
      checked: false,
      group: 1,
      position: 1,
    },
    {
      value: VALUATION_REQUEST_STATUS.CONFIRMED,
      text: VALUATION_REQUEST_STATUS_NAME[VALUATION_REQUEST_STATUS.CONFIRMED],
      checked: false,
      group: 1,
      position: 2,
    },
    {
      value: VALUATION_REQUEST_STATUS.SUCCESS,
      text: VALUATION_REQUEST_STATUS_NAME[VALUATION_REQUEST_STATUS.SUCCESS],
      checked: false,
      group: 1,
      position: 3,
    },
    {
      value: VALUATION_REQUEST_STATUS.NO_CONTACT,
      text: VALUATION_REQUEST_STATUS_NAME[VALUATION_REQUEST_STATUS.NO_CONTACT],
      checked: false,
      group: 1,
      position: 4,
    },
    {
      value: VALUATION_REQUEST_STATUS.CANCEL,
      text: VALUATION_REQUEST_STATUS_NAME[VALUATION_REQUEST_STATUS.CANCEL],
      checked: false,
      group: 1,
      position: 4,
    },
  ],
  VALUATION_REQUEST_STATUS_OPTION: values(VALUATION_REQUEST_STATUS).map(
    (status) => {
      return {
        id: status,
        name: VALUATION_REQUEST_STATUS_NAME[status],
      };
    },
  ),
  MISSING_ENTRY_FILTER_BILL: {
    1: 'Chưa nhập mã quẹt thẻ',
    2: 'Chưa nhập hợp đồng trả góp',
    3: 'Chưa nhập mã tham chiếu CK'
  },
};
